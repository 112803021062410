/**
==============================================================================
Copyright (C) 2019 - Philip Paquette, Steven Bocco

 This program is free software: you can redistribute it and/or modify it under
 the terms of the GNU Affero General Public License as published by the Free
 Software Foundation, either version 3 of the License, or (at your option) any
 later version.

 This program is distributed in the hope that it will be useful, but WITHOUT
 ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more
 details.

 You should have received a copy of the GNU Affero General Public License along
 with this program.  If not, see <https:www.gnu.org/licenses/>.
==============================================================================
**/
export const Coordinates = {
  ADR: { unit: ["793.5", "1048.0"], disl: ["782.0", "1038.0"] },
  AEG: { unit: ["1043.5", "1230.0"], disl: ["1032.0", "1220.0"] },
  ALB: { unit: ["906.5", "1113.0"], disl: ["895.0", "1103.0"] },
  ANK: { unit: ["1301.5", "1110.0"], disl: ["1290.0", "1100.0"] },
  APU: { unit: ["791.5", "1106.0"], disl: ["780.0", "1096.0"] },
  ARM: { unit: ["1484.5", "1090.0"], disl: ["1473.0", "1080.0"] },
  BAL: { unit: ["878.5", "610.0"], disl: ["867.0", "600.0"] },
  BAR: { unit: ["1162.5", "73.0"], disl: ["1151.0", "63.0"] },
  BEL: { unit: ["561.5", "753.0"], disl: ["550.0", "743.0"] },
  BER: { unit: ["771.5", "690.0"], disl: ["760.0", "680.0"] },
  BLA: { unit: ["1233.5", "1000.0"], disl: ["1222.0", "990.0"] },
  BOH: { unit: ["806.5", "814.0"], disl: ["795.0", "804.0"] },
  BOT: { unit: ["941.5", "485.0"], disl: ["930.0", "475.0"] },
  BRE: { unit: ["404.5", "819.0"], disl: ["393.0", "809.0"] },
  BUD: { unit: ["950.5", "904.0"], disl: ["939.0", "894.0"] },
  BUL: { unit: ["1048.5", "1068.0"], disl: ["1037.0", "1058.0"] },
  "BUL/EC": { unit: ["1127.0", "1067.0"], disl: ["1115.5", "1057.0"] },
  "BUL/SC": { unit: ["1070.0", "1140.0"], disl: ["1058.5", "1130.0"] },
  BUR: { unit: ["559.5", "871.0"], disl: ["548.0", "861.0"] },
  CLY: { unit: ["436.5", "492.0"], disl: ["425.0", "482.0"] },
  CON: { unit: ["1145.5", "1137.0"], disl: ["1134.0", "1127.0"] },
  DEN: { unit: ["703.5", "587.0"], disl: ["692.0", "577.0"] },
  EAS: { unit: ["1218.5", "1311.0"], disl: ["1207.0", "1301.0"] },
  EDI: { unit: ["473.5", "514.0"], disl: ["462.0", "504.0"] },
  ENG: { unit: ["394.5", "751.0"], disl: ["383.0", "741.0"] },
  FIN: { unit: ["988.5", "380.0"], disl: ["977.0", "370.0"] },
  GAL: { unit: ["999.5", "831.0"], disl: ["988.0", "821.0"] },
  GAS: { unit: ["422.5", "912.0"], disl: ["411.0", "902.0"] },
  GOL: { unit: ["556.0", "1060.0"], disl: ["544.5", "1050.0"] },
  GRE: { unit: ["966.5", "1190.0"], disl: ["955.0", "1180.0"] },
  HEL: { unit: ["651.5", "631.0"], disl: ["640.0", "621.0"] },
  HOL: { unit: ["596.5", "711.0"], disl: ["585.0", "701.0"] },
  ION: { unit: ["846.5", "1286.0"], disl: ["835.0", "1276.0"] },
  IRI: { unit: ["335.5", "661.0"], disl: ["324.0", "651.0"] },
  KIE: { unit: ["683.5", "701.0"], disl: ["672.0", "691.0"] },
  LON: { unit: ["488.5", "675.0"], disl: ["477.0", "665.0"] },
  LVN: { unit: ["1025.5", "567.0"], disl: ["1014.0", "557.0"] },
  LVP: { unit: ["450.5", "576.0"], disl: ["439.0", "566.0"] },
  LYO: { unit: ["514.3", "1055.0"], disl: ["502.8", "1045.0"] },
  MAO: { unit: ["141.8", "835.3"], disl: ["130.3", "825.3"] },
  MAR: { unit: ["524.5", "975.0"], disl: ["513.0", "965.0"] },
  MID: { unit: ["126.0", "902.0"], disl: ["114.5", "892.0"] },
  MOS: { unit: ["1200.5", "590.0"], disl: ["1189.0", "580.0"] },
  MUN: { unit: ["693.5", "828.0"], disl: ["682.0", "818.0"] },
  NAF: { unit: ["325.5", "1281.0"], disl: ["314.0", "1271.0"] },
  NAO: { unit: ["180.1", "288.2"], disl: ["168.6", "278.2"] },
  NAP: { unit: ["806.5", "1170.0"], disl: ["795.0", "1160.0"] },
  NAT: { unit: ["238.0", "427.0"], disl: ["226.5", "417.0"] },
  NRG: { unit: ["605.0", "250.0"], disl: ["593.5", "240.0"] },
  NTH: { unit: ["553.5", "560.0"], disl: ["542.0", "550.0"] },
  NWG: { unit: ["652.7", "181.8"], disl: ["641.2", "171.8"] },
  NWY: { unit: ["703.5", "410.0"], disl: ["692.0", "400.0"] },
  PAR: { unit: ["488.5", "845.0"], disl: ["477.0", "835.0"] },
  PIC: { unit: ["523.5", "781.0"], disl: ["512.0", "771.0"] },
  PIE: { unit: ["630.5", "968.0"], disl: ["619.0", "958.0"] },
  POR: { unit: ["181.5", "1013.0"], disl: ["170.0", "1003.0"] },
  PRU: { unit: ["865.5", "690.0"], disl: ["854.0", "680.0"] },
  ROM: { unit: ["731.5", "1102.0"], disl: ["720.0", "1092.0"] },
  RUH: { unit: ["636.5", "779.0"], disl: ["625.0", "769.0"] },
  RUM: { unit: ["1096.5", "967.0"], disl: ["1085.0", "957.0"] },
  SER: { unit: ["933.5", "1050.0"], disl: ["922.0", "1040.0"] },
  SEV: { unit: ["1284.5", "845.0"], disl: ["1273.0", "835.0"] },
  SIL: { unit: ["832.5", "769.0"], disl: ["821.0", "759.0"] },
  SKA: { unit: ["735.5", "518.0"], disl: ["724.0", "508.0"] },
  SMY: { unit: ["1253.5", "1210.0"], disl: ["1242.0", "1200.0"] },
  SPA: { unit: ["335.5", "1039.0"], disl: ["324.0", "1029.0"] },
  "SPA/NC": { unit: ["289.0", "965.0"], disl: ["277.5", "955.0"] },
  "SPA/SC": { unit: ["291.0", "1166.0"], disl: ["279.5", "1156.0"] },
  STP: { unit: ["1166.5", "405.0"], disl: ["1155.0", "395.0"] },
  "STP/NC": { unit: ["1218.0", "222.0"], disl: ["1206.5", "212.0"] },
  "STP/SC": { unit: ["1066.0", "487.0"], disl: ["1054.5", "477.0"] },
  SWE: { unit: ["829.5", "459.0"], disl: ["818.0", "449.0"] },
  SWI: { unit: ["642.0", "928.0"], disl: ["630.5", "918.0"] },
  SYR: { unit: ["1452.5", "1206.0"], disl: ["1441.0", "1196.0"] },
  TRI: { unit: ["825.5", "996.0"], disl: ["814.0", "986.0"] },
  TUN: { unit: ["622.5", "1300.0"], disl: ["611.0", "1290.0"] },
  TUS: { unit: ["686.5", "1034.0"], disl: ["675.0", "1024.0"] },
  TYN: { unit: ["720.0", "1160.0"], disl: ["708.5", "1150.0"] },
  TYR: { unit: ["742.5", "904.0"], disl: ["731.0", "894.0"] },
  TYS: { unit: ["698.5", "1149.1"], disl: ["687.0", "1139.1"] },
  UKR: { unit: ["1124.5", "800.0"], disl: ["1113.0", "790.0"] },
  VEN: { unit: ["707.5", "994.0"], disl: ["696.0", "984.0"] },
  VIE: { unit: ["855.5", "864.0"], disl: ["844.0", "854.0"] },
  WAL: { unit: ["428.5", "658.0"], disl: ["417.0", "648.0"] },
  WAR: { unit: ["983.5", "740.0"], disl: ["972.0", "730.0"] },
  WES: { unit: ["462.5", "1163.0"], disl: ["451.0", "1153.0"] },
  YOR: { unit: ["492.5", "616.0"], disl: ["481.0", "606.0"] },
};
export const SymbolSizes = {
  Fleet: { width: 40.0, height: 40.0 },
  Army: { width: 40.0, height: 40.0 },
  Wing: { width: 40.0, height: 40.0 },
  DislodgedFleet: { width: 40.0, height: 40.0 },
  DislodgedArmy: { width: 40.0, height: 40.0 },
  DislodgedWing: { width: 40.0, height: 40.0 },
  FailedOrder: { width: 30.0, height: 30.0 },
  SupplyCenter: { width: 20.0, height: 20.0 },
  BuildUnit: { width: 60.0, height: 60.0 },
  RemoveUnit: { width: 50.0, height: 50.0 },
  WaivedBuild: { width: 40.0, height: 40.0 },
  HoldUnit: { width: 66.6, height: 66.6 },
  SupportHoldUnit: { width: 76.6, height: 76.6 },
  ConvoyTriangle: { width: 66.4, height: 57.4 },
  Stroke: { width: 6.0, height: 10.0 },
};
export const Colors = {
  AUSTRIA: "#c48f85",
  ENGLAND: "darkviolet",
  FRANCE: "royalblue",
  GERMANY: "#a08a75",
  ITALY: "forestgreen",
  RUSSIA: "#757d91",
  TURKEY: "#b9a61c",
};
